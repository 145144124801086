import './Page.css';
import React, { useState } from 'react';
import TRANSLATIONS from '../translations';
import { useApp } from '../AppCtx';

function Page4() {
  const { assumptionsData, loanAmount, loanYears, lang, getMonthlyPayment, getPlanTable, settings } = useApp();

  return (
    <div className="Page Page4">
      <h2>{TRANSLATIONS[lang]['GLOBAL_TITLE_1']}</h2>
      
      <div className='Page-table '>
        <table className="table">
          <tbody>
            <tr>
              <td><h5>{TRANSLATIONS[lang]['PAGE4_loanTerms']}</h5></td>
              <td><h5>{TRANSLATIONS[lang]['PAGE4_loanRecap']}</h5></td>
            </tr>
            <tr>
              <td>{TRANSLATIONS[lang]['PAGE4_totalConstProject']}: <b>{assumptionsData.financingAmount}</b></td>
              <td>{TRANSLATIONS[lang]['PAGE4_instalmentVal']}: <b>{getMonthlyPayment(assumptionsData.interestRate, loanYears, loanAmount)}</b></td>
            </tr>
            <tr>
              <td>{TRANSLATIONS[lang]['PAGE4_debtPercentage']}: <b>{assumptionsData.percentageDebtFinancing}</b></td>
              <td>{TRANSLATIONS[lang]['PAGE4_instalNum']}: <b>{12 * loanYears}</b></td>
            </tr>
            <tr>
              <td>{TRANSLATIONS[lang]['PAGE4_amountFinanced']}: <b>{loanAmount}</b></td>
              <td>{TRANSLATIONS[lang]['PAGE4_amountInterest']}: <b>{((12 * loanYears * getMonthlyPayment(assumptionsData.interestRate, loanYears, loanAmount)) - loanAmount).toFixed(2)}</b></td>
            </tr>
            <tr>
              <td>{TRANSLATIONS[lang]['PAGE4_interestRate']}: <b>{assumptionsData.interestRate}</b></td>
              <td>{TRANSLATIONS[lang]['PAGE4_totalConstLoan']}: <b>{((12 * loanYears * getMonthlyPayment(assumptionsData.interestRate, loanYears, loanAmount))).toFixed(2)}</b></td>
            </tr>
            <tr>
              <td>{TRANSLATIONS[lang]['PAGE4_tenor']}: <b>{assumptionsData.tenor}</b></td>
              <td></td>
            </tr>
          </tbody>
        </table>

        
      </div>

      

      <div className='Page-table'>
        <table className="table">
          <tbody>
            {getPlanTable().map((row, idx1) => (
              <tr key={idx1}>
                {row.map((it, idx2) => (
                  <td key={idx2}>
                    {idx1 ===0 ? TRANSLATIONS[lang][it] : it}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Page4;
