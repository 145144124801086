import './Page.css';
import React, { useEffect, useState } from 'react';
import TRANSLATIONS from '../translations';
import { useApp } from '../AppCtx';

function Page1() {
  const { assumptionsData, updateAssumptionsData, initialAssumptionsData, assumptionsDataRO, lang } = useApp();

  const reds = {
    electricityTariff: true,
    deductableVAT: true,
    percentageDebtFinancing: true,
    interestRate: true,
    tenor: true,
    financingAmount: true
  }
  
  const blacks = {
    totalProjectCostFactor: true,
    totalProjectCost: true,
    totalCostOfTheLoan: true,
    returnOnInvestmentWithBusinessFunds: true,
    loanRepaymentPeriod: true,
    returnOnInvestmentExcludedVAT: true,
    returnOnInvestmentIncludedVAT : true
  }

  useEffect(() => {
    updateAssumptionsData(null,null);
  }, []);

  return (
    <div className="Page">
      <h5>{TRANSLATIONS[lang]['GLOBAL_TITLE_1']}</h5>
      <p><b><u>{TRANSLATIONS[lang]['NAVBAR_LINK1']}</u></b></p>

      <form>
        {Object.keys(initialAssumptionsData).map((k) => (
          <div key={k}>
            <div className={'Page-label ' + (reds[k] ? 'Page-label-red '  : '') + (blacks[k] ? 'Page-label-black '  : '')}>
              <label htmlFor={k} dangerouslySetInnerHTML={{ __html : TRANSLATIONS[lang]['PAGE1_' + k] }}></label>
            </div>
            <div className='Page-input'>
              <input
                className='form-control'
                type="number"
                id={k}
                name={k}
                disabled={assumptionsDataRO[k] ? true : false}
                value={assumptionsData[k]}
                onChange={(e) => updateAssumptionsData(k, e.target.value)}
                required
              />
            </div>
          </div>
        ))}
      </form>
    </div>
  );
}

export default Page1;
