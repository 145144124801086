const TRANSLATIONS_EN = {
  'NAVBAR_LINK1': 'Assumptions',
  'NAVBAR_LINK2': 'Financial impact chart',
  'NAVBAR_LINK3': 'Financial outputs',
  'NAVBAR_LINK4': 'Loan calculator',

  'GLOBAL_TITLE_1': 'Financial Model for Solar PV Installations',
  'GLOBAL_TITLE_2': 'SME Finacial Model',
  'GLOBAL_YEAR': 'Year',
  'GLOBAL_DATE': 'Date',

  'PAGE1_capacitySolarPVInstallation': 'Capacity solar PV installation [kW]',
  'PAGE1_requiredRooftopArea': 'Required rooftop area for this capacity in square meter with assumption five square meter per KW [m2]',
  'PAGE1_energyProductionFactor': 'Energy production factor [kWh/kW]',
  'PAGE1_annualProductionSelfConsumed': 'Annual production self-consumed [%]',
  'PAGE1_electricityTariff': 'Electricity tariff paid to the electricity company for electricity [EUR/kWh]',
  'PAGE1_totalProjectCostFactor': 'Total project cost factor [EUR/kW]',
  'PAGE1_totalProjectCost': 'Total Project Cost [EUR]',
  'PAGE1_deductableVAT': 'Deductable VAT [EUR]',
  'PAGE1_percentageDebtFinancing': 'Percentage debt financing of total project cost [%]',
  'PAGE1_interestRate': 'Interest rate [%]',
  'PAGE1_tenor': 'Tenor (years)',
  'PAGE1_annualOperatingCostsPercentage': 'Annual operating costs % of total project costs [%]',
  'PAGE1_annualProductionFromInstallation': 'Annual production from the installation [kWh]',
  'PAGE1_monthlyProductionFromInstallation': 'Monthly production from the installation [kWh]',
  'PAGE1_annualEnergyCostSavings': 'Annual energy cost savings [EUR]',
  'PAGE1_monthlyEnergyCostSavings': 'Monthly energy cost savings [EUR]',
  'PAGE1_annualOperatingCosts': 'Annual operating costs [EUR]',
  'PAGE1_financingAmount': 'Financing amount [EUR]',
  'PAGE1_loanInstallmentAmount': 'Loan Installment amount (monthly) [EUR]',
  'PAGE1_yearlyLoanInstallmentsAmount': 'Yearly Loan Installments amount [EUR]',
  'PAGE1_amountOfInterest': 'Amount of Interest [EUR]',
  'PAGE1_totalCostOfTheLoan': 'Total cost of the loan [EUR]',
  'PAGE1_decreaseInProductionEfficiency': 'Expected decrease in annual production efficiency of panels [%]',
  'PAGE1_returnOnInvestmentWithBusinessFunds': 'Return on Investment (investment with business funds) [Years]',
  'PAGE1_loanRepaymentPeriod': 'Loan repayment period (100% financed by the bank) [Years]',
  'PAGE1_investmentAmountAfterVAT': 'Investment amount (including loan interest) after VAT deduction of VAT [EUR]',
  'PAGE1_returnOnInvestmentExcludedVAT': 'Return on Investment (100% financed by the bank and VAT excluded) [Years]',
  'PAGE1_returnOnInvestmentIncludedVAT': 'Return on Investment (100% financed by the bank and VAT excluded) [%]',

  'PAGE2_cashPositionTitle':'Cash Position (End of the year)',

  'PAGE3_financialMetric': 'Financial metric',
  'PAGE3_irr': 'IRR',
  'PAGE3_irr_desc': 'Over 25 years',
  'PAGE3_coverage': 'Coverage of debt service (principal and interest) and operating costs with energy savings:',
  'PAGE3_coverage_desc': 'during Loan Tenor',
  'PAGE3_initInvestment': 'Initial equity investment',
  'PAGE3_netExpectedCash': 'Net Expected cash inflow (energy savings)',
  'PAGE3_debtService': 'Debt Service',
  'PAGE3_debtServicePart': 'of which interest amount',
  'PAGE3_operationCosts': 'Operating Costs',
  'PAGE3_tax': 'Corporate tax',
  'PAGE3_inflow': 'Expected cash inflow net',
  'PAGE3_position': 'Cash Position (End of the year)',
  'PAGE3_decrease': 'Expected decrease in annual production efficiency',

  'PAGE4_loanTerms': 'Loan terms',
  'PAGE4_loanRecap': 'Loan recap',
  'PAGE4_totalConstProject': 'Total cost of the project [EUR]',
  'PAGE4_instalmentVal': 'Instalment value [EUR]',
  'PAGE4_debtPercentage': 'Percentage debt financing of total project cost [%]',
  'PAGE4_instalNum': 'Number of instalments:',
  'PAGE4_amountFinanced': 'Amount financed [EUR]:',
  'PAGE4_amountInterest': 'Amount of Interest [EUR]:',
  'PAGE4_interestRate': 'Interest rate [%]:',
  'PAGE4_totalConstLoan': 'Total cost of the loan [EUR]: ',
  'PAGE4_tenor': 'Tenor',
  'PAGE4_startBalance': 'Starting balance',
  'PAGE4_endBalance': 'Ending balance',
  'PAGE4_instalment': 'Instalment',
  'PAGE4_principal': 'Principal',
  'PAGE4_interest': 'Interest',

};
const TRANSLATIONS_AL = {
  "NAVBAR_LINK1": "Supozime",
  "NAVBAR_LINK2": "Tabela e ndikimit financiar",
  "NAVBAR_LINK3": "Rezultate financiare",
  "NAVBAR_LINK4": "Kalkulatori i kredisë",
  "GLOBAL_TITLE_1": "Modeli financiar për instalimin e solarëve fotovoltaik (FV)",
  "GLOBAL_TITLE_2": "Modeli Financiar i NVM-ve",
  "GLOBAL_YEAR": "Viti",
  "GLOBAL_DATE": "Data",
  "PAGE1_capacitySolarPVInstallation": "Kapaciteti i instalimit të solarëve fotovoltaik (FV)[kW]",
  "PAGE1_requiredRooftopArea": "Sipërfaqja e kërkuar e kulmit/çatisë për kapacitet të supozuar 5 metër katror për KW [m2]",
  "PAGE1_energyProductionFactor": "Faktori i prodhimit të energjisë [kWh/kW]",
  "PAGE1_annualProductionSelfConsumed": "Prodhimi vjetor për vet konsum [%]",
  "PAGE1_electricityTariff": "Tarifa e energjisë elektrike që i paguhet kompanisë së energjisë elektrike [EUR/kWh]",
  "PAGE1_totalProjectCostFactor": "Faktori i shpenzimeve totale të projektit [EUR/kW]",
  "PAGE1_totalProjectCost": "Kostoja totale e projektit [EUR]",
  "PAGE1_deductableVAT": "TVSH e zbritshme [EUR]",
  "PAGE1_percentageDebtFinancing": "Përqindja e financimit me kredi e kostos totale të projektit [%]",
  "PAGE1_interestRate": "Norma e interesit [%]",
  "PAGE1_tenor": "Kohëzgjatja ( vitet)",
  "PAGE1_annualOperatingCostsPercentage": "Shpenzimet operative vjetore dhe % e kostos totale të projektit [%]",
  "PAGE1_annualProductionFromInstallation": "Prodhimi vjetor nga instalimi [kWh]",
  "PAGE1_monthlyProductionFromInstallation": "Prodhimi mujor nga instalimi [kWh]",
  "PAGE1_annualEnergyCostSavings": "Kursimi vjetor i shpenzimit të energjisë  [EUR]",
  "PAGE1_monthlyEnergyCostSavings": "Kursimi mujor i shpenzimit të energjisë [EUR]",
  "PAGE1_annualOperatingCosts": "Shpenzimet operative vjetore [EUR]",
  "PAGE1_financingAmount": "Vlera e financuar [EUR]",
  "PAGE1_loanInstallmentAmount": "Vlera e këstit të kredisë (monthly) [EUR]",
  "PAGE1_yearlyLoanInstallmentsAmount": "Vlera vjetore e këstit të kredisë [EUR]",
  "PAGE1_amountOfInterest": "Vlera e interesit [EUR]",
  "PAGE1_totalCostOfTheLoan": "Kostoja totale e kredisë [EUR]",
  "PAGE1_decreaseInProductionEfficiency": "Zvogëlimi i pritshëm i efikasitetit vjetor të paneleve [%]",
  "PAGE1_returnOnInvestmentWithBusinessFunds": "Kthimi në investim (investimet me fonde të biznesit) [Years]",
  "PAGE1_loanRepaymentPeriod": "Periudha e kthimit të kredisë (100% financuar nga banka) [Vite]",
  "PAGE1_investmentAmountAfterVAT": "Vlera e investimit ( përfshirë interesin e kredisë) pas zbritjes së TVSH-së [EUR]",
  "PAGE1_returnOnInvestmentExcludedVAT": "Kthimi në Investim (100% financuar nga banka pa TVSH) [Vite]",
  "PAGE1_returnOnInvestmentIncludedVAT": "Kthimi në Investim (100% financuar nga banka pa TVSH) [%]",
  "PAGE2_cashPositionTitle": "Gjendja e parasë së gatshme (në fund të vitit)",
  "PAGE3_financialMetric": "Matja financiare",
  "PAGE3_irr": "IRR",
  "PAGE3_irr_desc": "Mbi 25 vite",
  "PAGE3_coverage": "Mbulimi për shërbimet e borxhit (kryegjëja dhe interesi)  dhe shpenzimet operative me kursim të energjisë:",
  "PAGE3_coverage_desc": "Gjatë afatit të kredisë",
  "PAGE3_initInvestment": "Investimi fillestar në kapital",
  "PAGE3_netExpectedCash": "Të hyrat neto të pritshme ( kursimet e energjisë)",
  "PAGE3_debtService": "Shërbimi i borxhit",
  "PAGE3_debtServicePart": "Çfarë shumë të interesit",
  "PAGE3_operationCosts": "Shpenzimet operative",
  "PAGE3_inflow": "Të hyrat neto të pritshme",
  'PAGE3_tax': 'Taksa e korporatave',
  "PAGE3_position": "Gjendja e parasë së gatshme (Fund të vitit)",
  "PAGE3_decrease": "Rënia e pritshme e efikasitetit vjetor në prodhim",
  "PAGE4_loanTerms": "Kushtet e kredisë",
  "PAGE4_loanRecap": "Përmbledhje për kredinë",
  "PAGE4_totalConstProject": "Kostoja e përgjithshme e projektit [EUR]",
  "PAGE4_instalmentVal": "Vlera e kësteve [EUR]",
  "PAGE4_debtPercentage": "Përqindja e financimit të borxhit nga vlera e përgjithshme e projektit [%]",
  "PAGE4_instalNum": "Numri i kësteve:",
  "PAGE4_amountFinanced": "Shuma/vlera e financuar [EUR]:",
  "PAGE4_amountInterest": "Shuma/vlera e interesit [EUR]:",
  "PAGE4_interestRate": "Norma e interesit [%]:",
  "PAGE4_totalConstLoan": "Kostoja e përgjithshme e kredisë [EUR]: ",
  "PAGE4_tenor": "Kohëzgjatja",
  "PAGE4_startBalance": "Bilanci fillestar",
  "PAGE4_endBalance": "Bilanci përfundimtar",
  "PAGE4_instalment": "Këstet",
  "PAGE4_principal": "Kryegjëja",
  "PAGE4_interest": "Interesi"
}

export const TRANSLATIONS = {
  'en': TRANSLATIONS_EN,
  'al': TRANSLATIONS_AL
}

export default TRANSLATIONS;

