import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

function roundToThreeDecimals(number) {
  let roundedNumber = parseFloat(number).toFixed(3).replace(/\.?0+$/, '');
  return parseFloat(roundedNumber);
}

export const AppProvider = ({ children }) => {

  const initialAssumptionsData = {
    capacitySolarPVInstallation: 100.000,
    requiredRooftopArea: 500.000,
    energyProductionFactor: 1350.000,
    annualProductionSelfConsumed: 100.000,
    electricityTariff: 0.107,
    totalProjectCostFactor: 800.000,
    totalProjectCost: 80000.000,
    deductableVAT: 12.203,
    percentageDebtFinancing: 100.000,
    interestRate: 6.000,
    tenor: 6.000,
    annualOperatingCostsPercentage: 0.100,
    annualProductionFromInstallation: 135000.000,
    monthlyProductionFromInstallation:  11250.000,
    annualEnergyCostSavings: 14445.000,
    monthlyEnergyCostSavings: 1204.000,
    annualOperatingCosts: 80.000,
    financingAmount: 80000.000,
    loanInstallmentAmount: 1326.000,
    yearlyLoanInstallmentsAmount: 15910.000,
    amountOfInterest:  15460.000,
    totalCostOfTheLoan:  95460.000,
    decreaseInProductionEfficiency: 0.25,
    returnOnInvestmentWithBusinessFunds: 5.500,
    loanRepaymentPeriod: 6.600,
    investmentAmountAfterVAT: 83256.000,
    returnOnInvestmentExcludedVAT: 5.760,
    returnOnInvestmentIncludedVAT: 0.000,
  };
  const assumptionsDataRO = {
    requiredRooftopArea: true,
    totalProjectCost: true,
    annualProductionFromInstallation: true,
    monthlyProductionFromInstallation: true,
    annualEnergyCostSavings: true,
    monthlyEnergyCostSavings: true,
    deductableVAT: true,
    annualOperatingCosts: true,
    financingAmount: true,
    loanInstallmentAmount: true,
    yearlyLoanInstallmentsAmount: true,
    amountOfInterest: true,
    totalCostOfTheLoan: true,
    returnOnInvestmentWithBusinessFunds: true,
    loanRepaymentPeriod: true,
    investmentAmountAfterVAT: true,
    returnOnInvestmentExcludedVAT: true,
    returnOnInvestmentIncludedVAT: true,
  }
  

  const assumptionsDataProcess = (assumptionsData) => {
    assumptionsData.requiredRooftopArea = roundToThreeDecimals(
      assumptionsData.capacitySolarPVInstallation * 5
      );
    assumptionsData.totalProjectCost = roundToThreeDecimals(
      assumptionsData.capacitySolarPVInstallation * assumptionsData.totalProjectCostFactor
      );
    assumptionsData.deductableVAT = roundToThreeDecimals(
      assumptionsData.totalProjectCost - (assumptionsData.totalProjectCost/1.18)
      );
    assumptionsData.annualProductionFromInstallation = roundToThreeDecimals(
      assumptionsData.capacitySolarPVInstallation * 
      assumptionsData.energyProductionFactor
      );
    assumptionsData.monthlyProductionFromInstallation = roundToThreeDecimals(
      assumptionsData.annualProductionFromInstallation / 12
      );
    assumptionsData.annualEnergyCostSavings = roundToThreeDecimals(
        assumptionsData.annualProductionFromInstallation * 
        assumptionsData.annualProductionSelfConsumed *
        assumptionsData.electricityTariff /
        100);
    assumptionsData.monthlyEnergyCostSavings = roundToThreeDecimals(
        assumptionsData.annualEnergyCostSavings /
        12);
    assumptionsData.annualOperatingCosts = roundToThreeDecimals(
        assumptionsData.annualOperatingCostsPercentage *
        assumptionsData.totalProjectCost / 
        100);
    assumptionsData.financingAmount = roundToThreeDecimals(
      assumptionsData.totalProjectCost *
      assumptionsData.percentageDebtFinancing / 
      100);
    assumptionsData.loanInstallmentAmount = roundToThreeDecimals(
      assumptionsData.loanInstallmentAmount
      );
    assumptionsData.yearlyLoanInstallmentsAmount = roundToThreeDecimals(
      assumptionsData.loanInstallmentAmount * 12
      );

    let loanAmount = (assumptionsData.financingAmount * assumptionsData.percentageDebtFinancing / 100.0);
    assumptionsData.amountOfInterest =((12 * loanYears * getMonthlyPayment(assumptionsData.interestRate, loanYears, loanAmount)) - loanAmount).toFixed(2);
    assumptionsData.totalCostOfTheLoan = ((12 * loanYears * getMonthlyPayment(assumptionsData.interestRate, loanYears, loanAmount))).toFixed(2);

      
    assumptionsData.returnOnInvestmentWithBusinessFunds = (assumptionsData.totalProjectCost/assumptionsData.annualEnergyCostSavings).toFixed(3);
    assumptionsData.loanRepaymentPeriod = (assumptionsData.totalCostOfTheLoan/assumptionsData.annualEnergyCostSavings).toFixed(3);
    assumptionsData.investmentAmountAfterVAT = assumptionsData.totalCostOfTheLoan - assumptionsData.deductableVAT;
    assumptionsData.returnOnInvestmentExcludedVAT = (assumptionsData.investmentAmountAfterVAT/assumptionsData.annualEnergyCostSavings).toFixed(3);
    assumptionsData.returnOnInvestmentIncludedVAT = (assumptionsData.annualEnergyCostSavings/assumptionsData.investmentAmountAfterVAT * 100).toFixed(3);
  };
  const [lang, setLang] = useState('en');
  const [page3Res, setPage3Res] = useState(null);
  const [settings, setSettings] = useState(null);
  const [loanAmount, setLoanAmount] = useState(80000.0);
  const [loanYears, setLoanYears] = useState(6);
  const [irr, setIrr] = useState(null);
  const [covOfDebt, setCovOfDebt] = useState(null);
  const [assumptionsData, setAssumptionsData] = useState(initialAssumptionsData);

  const updateAssumptionsData = (name, value) => {
    if (name) {
      assumptionsData[name] = +value;
    }
    assumptionsDataProcess(assumptionsData);
    setAssumptionsData(prevAssumptionsData => ({
      ...prevAssumptionsData,
      ...assumptionsData,
    }));
    setLoanAmount(assumptionsData.financingAmount * assumptionsData.percentageDebtFinancing / 100.0);
  };

  const getMonthlyPayment = (interestRate, loanYears, loanAmount)  => {
    let monthlyRate = interestRate / 100 / 12;
    let presentValue = -loanAmount;
    let payment = (monthlyRate * presentValue) / (1 - Math.pow(1 + monthlyRate, -(loanYears*12)));
    return -payment.toFixed(2);  // Return the negative value to match Excel's PMT function
  };

  const calculatePrincipalPayment = (paymentNumber, interestRate, loanYears, loanAmount)  => {
    let monthlyRate = interestRate / 100 / 12;
    let presentValue = -loanAmount;
    let numberOfPayments = loanYears * 12;

    // Calculate the principal portion of the payment using the formula
    let principalPayment = presentValue * (Math.pow(1 + monthlyRate, paymentNumber - 1) * monthlyRate) / (Math.pow(1 + monthlyRate, numberOfPayments) - 1);

    return -principalPayment.toFixed(2);  // Return the negative value to match Excel's PPMT function
  }

  const getPlanTable = () => {
    let res = [
      ['#', 'GLOBAL_DATE', 'PAGE4_startBalance', 'PAGE4_instalment', 'PAGE4_principal', 'PAGE4_interest', 'PAGE4_endBalance']
    ];

    const nofp = 12 * loanYears;
    const mp = getMonthlyPayment(assumptionsData.interestRate, loanYears, loanAmount);

    let balance = loanAmount;

    const currentDate = new Date();
    currentDate.setMonth(1); //TODO hardcoded february check why
    currentDate.setDate(1);

    for (let i = 1; i <= nofp; i++) {

      const updatedYear = currentDate.getFullYear();
      const updatedMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1
      const updatedDay = currentDate.getDate();


      const p = calculatePrincipalPayment(i, assumptionsData.interestRate, loanYears, loanAmount);
      const balanceafter = (balance - p).toFixed(2);
      res.push([i, `${updatedDay}/${updatedMonth}/${updatedYear}`, balance, mp, p, (mp - p).toFixed(2), balanceafter]);
      balance = balanceafter;

      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return res;

  };

  const calcOutput = () => {
    let res = [
      [''],
      ['PAGE3_initInvestment'],
      ['GLOBAL_YEAR'],
      ['PAGE3_netExpectedCash'],
      ['PAGE3_debtService'],
      ['PAGE3_debtServicePart'],
      ['PAGE3_operationCosts'],
      ['PAGE3_tax'],
      ['PAGE3_inflow'],
      ['PAGE3_position'],
      ['PAGE3_decrease']
    ];
    const currentYear = new Date().getFullYear();
    let lasposition = 0.0;
    const mp = getMonthlyPayment(assumptionsData.interestRate, loanYears, loanAmount);
    for(const i of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25]) {
      const decrease = (i * assumptionsData.decreaseInProductionEfficiency - assumptionsData.decreaseInProductionEfficiency);
      const val = (assumptionsData.annualEnergyCostSavings*(1-decrease/100)).toFixed(1);
      const tenor = i !== 0 && i <= assumptionsData.tenor ? -assumptionsData.yearlyLoanInstallmentsAmount: 0; 
      const position = (+(+val - assumptionsData.annualOperatingCosts + tenor).toFixed(1) + (+lasposition)).toFixed(1);

      let iterstAmountPart = 0;
      for (let idx1 = (i-1) * 12 + 1; idx1 <= (i-1) * 12 + 12; idx1++) {
        const p = calculatePrincipalPayment(idx1, assumptionsData.interestRate, loanYears, loanAmount);
        iterstAmountPart += +(mp - p).toFixed(2);
      }

      res[0].push(currentYear + i);
      res[1].push(i === 0 ? (assumptionsData.financingAmount - assumptionsData.totalProjectCost).toFixed(1) : '');
      res[2].push(i > 0 ? i : '');
      res[3].push(i > 0 ? val : '');
      res[4].push(tenor !== 0  ? tenor: '-');
      res[5].push(i > 0 & iterstAmountPart > 0 ? (-iterstAmountPart.toFixed(1)) : '-');
      res[6].push(i > 0 ? -assumptionsData.annualOperatingCosts : '');
      res[7].push('-');
      res[8].push(i > 0 ? 
        (val - assumptionsData.annualOperatingCosts + tenor).toFixed(1)
        : '-');
      res[9].push(i > 0 ? position : '');
      res[10].push(i > 0 ? decrease + '%' : '');
      lasposition = i > 0 ? position : 0.0;
    }
    if(res) {
      const cashFlows = res[8].slice(2);
      setIrr(calculateIRR(cashFlows));

      let count1 = 0;
      let count2 = 0;
      let count3 = 0;
      for(let cnt1 = 0; cnt1 < assumptionsData.tenor; cnt1++) {
        count1 += +res[3][cnt1 + 2];
        count2 += +res[6][cnt1 + 2];
        count3 += +res[4][cnt1 + 2];
      }
      setCovOfDebt(((count1+count2)/-count3 * 100).toFixed(0))
    }
    setPage3Res(res);
    return res;
  };

  const calculateIRR = (cashFlows) => {
    const numericCashFlows = cashFlows.map(flow => parseFloat(flow) || 0);
    const irrResult = calculateIRRNewtonRaphson(numericCashFlows);
    return (irrResult * 100).toFixed(0);
  };

  const calculateIRRNewtonRaphson = (cashFlows) => {
    const epsilon = 0.00001;
    let irr = 0.1; // Initial guess

    for (let i = 0; i < 1000; i++) {
      const npv = calculateNPV(irr, cashFlows);

      if (Math.abs(npv) < epsilon) {
        return irr;
      }

      const derivative = calculateDerivative(irr, cashFlows);
      irr = irr - npv / derivative;
    }

    // If the method doesn't converge, return null or handle the case accordingly
    return null;
  };

  const calculateNPV = (rate, cashFlows) => {
    return cashFlows.reduce((npv, cashFlow, t) => npv + cashFlow / Math.pow(1 + rate, t), 0);
  };

  const calculateDerivative = (rate, cashFlows) => {
    return cashFlows.reduce((derivative, cashFlow, t) => derivative - (t * cashFlow) / Math.pow(1 + rate, t + 1), 0);
  };

  return (
    <AppContext.Provider value={{ 
      initialAssumptionsData,
      assumptionsData, 
      assumptionsDataRO,
      updateAssumptionsData, 
      calcOutput,
      loanAmount,
      loanYears,
      getMonthlyPayment,
      getPlanTable,
      lang, 
      setLang,
      settings,
      setSettings,
      irr,
      covOfDebt,
      page3Res
    }}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
};
