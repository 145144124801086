import './Page.css';
import TRANSLATIONS from '../translations';
import { useApp } from '../AppCtx';
import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

function Page2() {
  const chartRef = useRef(null);
  const { assumptionsData, calcOutput, initialAssumptionsData, assumptionsDataRO, lang } = useApp();

  useEffect(() => {
    const data = calcOutput();
    const labels = data[2].slice(2, data[0].length);
    const items = data[9].slice(2, data[0].length);
    const ctx = chartRef.current.getContext('2d');
    let myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: '',
          data: items,
        }],
      },
    });
    return () => {
      myChart.destroy();
    };
  }, []);
  

  return (
    <div className="Page">
      <h5>{TRANSLATIONS[lang]['GLOBAL_TITLE_1']}</h5>
      <p><b><u>{TRANSLATIONS[lang]['PAGE2_cashPositionTitle']}</u></b></p>
      <canvas ref={chartRef} width="1400" height="800"></canvas>
    </div>
  );
}

export default Page2;
