import './Page.css';
import React, { useState, useEffect } from 'react';
import TRANSLATIONS from '../translations';
import { useApp } from '../AppCtx';

function Page3() {
  const { irr, calcOutput, page3Res, covOfDebt, lang } = useApp();
  useEffect(() => {
    calcOutput();
  }, []);
  
  return (
    <div className="Page">
      <h5>{TRANSLATIONS[lang]['GLOBAL_TITLE_1']}</h5>
      <p><b><u>{TRANSLATIONS[lang]['GLOBAL_TITLE_2']}</u></b></p>

      
      <div>
        <p>{TRANSLATIONS[lang]['PAGE3_financialMetric']}<b>
          <br/>{TRANSLATIONS[lang]['PAGE3_irr']}: {irr}%</b> {TRANSLATIONS[lang]['PAGE3_irr_desc']}
          <br/><b>{TRANSLATIONS[lang]['PAGE3_coverage']} {covOfDebt}%</b> {TRANSLATIONS[lang]['PAGE3_coverage_desc']}</p>
      </div>

      <div className='Page-table'>
        <table className="table">
          {page3Res && <tbody>
            {page3Res.map((row, idx1) => (
              <tr key={idx1}>
                {row.map((it, idx2) => (
                  <td key={idx2}>
                    {idx2 ===0 ? TRANSLATIONS[lang][it] : it}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>}
        </table>
      </div>
    </div>
  );
}

export default Page3;
