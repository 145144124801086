import React, { useEffect, useState } from 'react';
import './App.css';
import TRANSLATIONS from './translations';
import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
import Page3 from './pages/Page3';
import Page4 from './pages/Page4';
import { useApp } from './AppCtx';


function App() {
  const { lang, setLang, setSettings } = useApp();
  const [currentPage, setCurrentPage] = useState('page1');
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/settings.json'); // Adjust the path based on your project structure
        const settings = await response.json();
        setSettings(settings)
      } catch (error) {
        console.error('Error fetching JSON:', error);
      }
    };

    const l = localStorage.getItem('lang');
    if(l) {
      setLang(l);
    }

    fetchData();
  }, [setSettings]);

  const handleLangChange = (event) => {
    setLang(event.target.value); // Update the state when the selection changes
    localStorage.setItem('lang', event.target.value);
  };

  return (
    <div className="App">
      
      <header className="App-header">
        <span>
          <img src='logo.svg'/>
        </span>
        <a
          className={currentPage === 'page1' ? 'active' : ''}
          onClick={() => handlePageChange('page1')}
        >
          {TRANSLATIONS[lang]['NAVBAR_LINK1']}
        </a>
        <a
          className={currentPage === 'page2' ? 'active' : ''}
          onClick={() => handlePageChange('page2')}
        >
          {TRANSLATIONS[lang]['NAVBAR_LINK2']}
        </a>
        <a
          className={currentPage === 'page3' ? 'active' : ''}
          onClick={() => handlePageChange('page3')}
        >
          {TRANSLATIONS[lang]['NAVBAR_LINK3']}
        </a>
        <a
          className={currentPage === 'page4' ? 'active' : ''}
          onClick={() => handlePageChange('page4')}
        >
          {TRANSLATIONS[lang]['NAVBAR_LINK4']}
        </a>

        <span>
          <select value={lang} onChange={handleLangChange}>
            <option value={'en'}>en</option>
            <option value={'al'}>al</option>
          </select>
        </span>
      </header>
      <main>
        {currentPage === 'page1' && <div><Page1/></div>}
        {currentPage === 'page2' && <div><Page2/></div>}
        {currentPage === 'page3' && <div><Page3/></div>}
        {currentPage === 'page4' && <div><Page4/></div>}
      </main>
    </div>
  );
}

export default App;
